import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'

export default class SingupForm extends React.PureComponent {

  render() {

    const { image, hasAgree } = this.props

    return (
      <Root>
        <div className="container">
          <div className="row">
            <div className="col-7">
              <img src={image} alt="" className="img-fluid"/>
            </div>
            <div className="col-5">
              <div className="wrapper clearfix">
                <div className="form-fields clearfix">
                  <div className="form-group">
                    <label htmlFor="email">Nom complet</label>
                    <input id="name" name="name" type="text" placeholder="Prénom et Nom"/>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input id="email" name="email" type="email" placeholder="Adresse email"/>
                  </div>
                </div>
                <div className="form-actions">
                  <p>
                    En vous inscrivant, vous reconnaissez avoir lu et accepté nos <Link to="/apropos#conditions">conditions
                    d'utlisation</Link>
                  </p>
                    <button disabled={!hasAgree} type="button">Oui, je m'inscris</button>
                </div>
              </div>
            </div>
          </div>
        </div>


      </Root>
    )
  }

}

const Root = styled.div`
  background: #1C4397;
  color: #FFF;
  margin-top: 48px;
  padding: 80px 0;
  
  img {
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.18);
    border-radius: 4px;
  }
  
  .wrapper {
    padding: 80px 80px 0 40px;
  }

  .form-group {
    width: 100%;
    display: block;
  }
  
  .form-group label {
    text-transform: uppercase;
    display: block;
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 1px;
    width: 100%;
  }
  
  .form-group input {
    border-radius: 2px;
    border: none;
    padding: 0 20px 0;
    line-height: 3;
    width: 100%;
    font-weight: 500;
    outline: 0 !important;
    font-size: 1.1em;
    color:#232323;
  }
  
  button {
    color: #FFF;
    border: 1px solid #FFF;
    color: #1C4397;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    border-radius: 2px;
    border: none;
    padding: 12px 20px 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
  
  a {
    color: #FFF;
    text-decoration: underline;
  }
  
`
