import React from 'react'
import SingupForm from '../components/SingupForm'
import Layout from '../components/Layout'
import PageHero from '../components/PageHero'
import Conditions from '../components/Conditions'

const assets = {
  banner: require('../resources/img/banner_kinds_c.png'),
  figure: require('../resources/img/vp_woman_creative_4.jpeg'),
}

export default class inscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = { check: false };
  }
  render(){
    return(
      <Layout page="register">

        <PageHero title="Démarrez avec Vitepay"
                  content={
                    <div>
                      Créez un seul compte pour accéder à tous nos services
                    </div>
                  }/>
        <div className="clearfix container mt-5 mb-5" style={{height: 400, overflow: "scroll"}}>
            <Conditions />
        </div>
        <div className="clearfix container mt-5 mb-5" >
          <div className="form-fields clearfix">
            <div className="form-group">
              <input id="agree" name="agree" onChange={() => this.setState({ check: !this.state.check })} type="checkbox" value="true" checked={this.state.check} style={style.form_group_input}/>
              <label htmlFor="agree" style={style.form_group_label}>J'accepte les conditions d'utilisation de VitePay</label>
            </div>
          </div>
        </div>

        <SingupForm image={assets.banner} hasAgree={this.state.check}/>

      </Layout>
    )
  }
}

const style ={
  form_group_label: {
    textTransform: "Capitalize",
    fontWeight: 500,
    fontSize: 12,
    letterSpacing: 1,
    marginLeft: 16
  }

}
